.home{
  overflow-x: hidden;
}

.downloadpage{
  background: #2E3236;
}

.herosvgcont img{
  max-width: 100%;
}

.barsvg{
  overflow: visible;
  max-width: 100%;
}

.down-section{
  background: #2E3236;
}

.playContainer{
  /* display: none; */
}

.playSvg{
  margin-top: 18px;
  filter: drop-shadow(2px 2px 6px rgba(3, 5, 20, 0.5));
  transition: 200ms all ease-in-out;
}

.playSvg:hover{
  transform: scale(1.06);
}

@media  (min-width: 768px){
  .herosvg1,
  .herosvg2{
    height: 36rem !important;
  }
}
