h1{
  font-size: 20px !important;
}

ul li{
  display: list-item !important;
  list-style: inside;
  margin: 10px 0;
  padding: 0 4px;
}
